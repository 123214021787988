<script>
import api from '@/command/api'
import DetailFormGroup from '@/components/DetailFormGroup'
import apiTool from '@/command/apiTool'
import { getAction, postAction, putAction } from '@/command/netTool'

export default {
  name: 'ticketSaleGateDetail',
  data() {
    return {
      ...api.command.getState(),
      detail: {},
      storeData: [],
    }
  },
  mounted() {
    const { id } = this.$route.query
    if (id) {
      api.command.getDetail
        .call(this, {
          url: `/farmTicketCheckPoint/detail?id=${id}`,
        })
        .then((res) => {
          this.storeSearch(res.scenicName)
          this.detail = {
            ...res,
          }
          this.$forceUpdate()
        })
    }
  },
  methods: {
    async storeSearch(value = '') {
      const searchResult = await api.command.search.call(this, {
        url: `/farmTicketScenic/list?name=${value}`,
      })
      this.storeData = searchResult.map((el) => {
        return {
          ...el,
          value: el.id,
        }
      })
    },
    /** end 数据查询 */

    getForm1() {
      return {
        title: '基本信息',
        type: 'cardForm',
        data: [
          {
            name: '所属景点',
            type: 'dropDownInput',
            cols: 12,
            key: 'scenicName',
            onInputSearch: this.storeSearch,
            valueKey: 'scenicId',
            inputProps: {
              placeholder: '搜索景点名称',
              addonBefore: <a-icon type="search" />,
            },
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            name: '名称',
            type: 'input',
            cols: 12,
            key: 'name',
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            name: '售检票口',
            type: 'radioButton',
            cols: 12,
            key: 'type',
            typeData: [
              {
                name: '检票点',
                value: '0',
              },
              {
                name: '售票点',
                value: '1',
              },
            ],
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            name: '位置',
            type: 'input',
            cols: 12,
            key: 'location',
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            key: 'remark',
            align: 'left',
            name: '备注',
            type: 'textArea',
            cols: 12,
            maxLength: 150,
            minHeight: '100px',
            showCount: true,
          },
        ],
      }
    },
    //#endregion 基础设置
    /** end 方法 */

    getFoot() {
      let that = this
      const left = [
        {
          name: '提交',
          type: 'primary',
          loading: this.loading,
          sumbit: true,
          display: true,
          onClick: (data) => {
            let obj = Object.assign({
              ...data,
            })
            console.log('sumbit', obj)
            // return
            api.command[data.id ? 'edit' : 'create']
              .call(this, {
                url: '/farmTicketCheckPoint',
                params: obj,
                isPost: false,
              })
              .then((result) => {
                setTimeout(() => {
                  this.$router.back()
                }, 500)
              })
          },
        },
        {
          name: '取消',
          type: 'default',
          onClick: () => this.$router.back(),
          display: true,
        },
      ].filter((e) => e.display)
      return {
        left,
      }
    },
    renderTab() {
      return [this.getForm1()]
    },
  },
  render() {
    return (
      <div>
        <DetailFormGroup
          foot={this.getFoot()}
          form={this.detail}
          typeData={{
            scenicName: this.storeData,
          }}
          data={this.renderTab()}
        />
      </div>
    )
  },
}
</script>
<style lang="less" scoped>
/deep/ .detail-form-group {
  height: calc(100vh - 175px);
}
</style>
